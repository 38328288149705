.btn-class {
  height: 35px;
  width: 45px;
  background-color: #321fdb;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 6px;

  &:hover {
    cursor: pointer;
    border: 1px solid #a299f1;
  }
}
